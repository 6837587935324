<template>
    <div class="bigData">
        <el-card class="box-card">
            <div class="title">大数据分析</div>
            <el-card class="card-first">
                <div id="main" style="width: 480px;height:300px;float: left;"></div>
                <div id="main2" style="width: 360px;height:220px;float: left;"></div>
            </el-card>
            <el-card class="card-second">
                <div slot="header" class="clearfix">
                    <span>全国种植指导政策</span>
                    <!-- <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button> -->
                </div>
                
                <div class="header-contant">
                    <el-table
                    ref="multipleTable"
                    :data="tableData2"
                    tooltip-effect="dark"
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
                    >
                    <!-- <div class="contant">
                        <p class="contant-title">政策名称</p>

                    </div> -->
                    <el-table-column
                        width="200"
                        label="政策名称"
                        class="contant"
                        prop="policyTitle"
                        :fit="false"
                        show-overflow-tooltip
                        align="center"
                    >
                    </el-table-column>
                    <!-- <div class="contant">
                        <p class="contant-title">下发时间</p>

                    </div> -->
                    <el-table-column
                        width="200"
                        label="下发时间"
                        class="contant"
                        prop="createTime"
                        :fit="false"
                        show-overflow-tooltip
                        align="center"
                    >
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="100" :fit="false" class="contant">
                        <template slot-scope="scope">
                            <p
                                class="check"
                                @click="router(scope.$index, scope.row)"
                            >查看</p>
                        </template>
                    </el-table-column>
                    </el-table>
                </div>
                
            </el-card>
            <el-card class="card-third">
                
                <div id="main3" style="width: 900px;height:450px;"></div>
                <template>
                    <el-select v-model="value" placeholder="请选择" class="select">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </template>
            </el-card>
            <el-card class="card-four">
                <div slot="header" class="clearfix">
                    <span>新闻通知</span>
                </div>
                <div class="four-contant" :data="tableData">
                    <div>新闻标题：{{tableData.newsTitle}}</div>
                    <div>新闻内容：{{tableData.context}}</div>
                </div>
            </el-card>
        </el-card>
        
    </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    data (){
        return {
            upTableData: {
                newsTitle: '',
                context: '',
            },
            tableData:[],
            tableData2:[],
            page:1,
            size:10,
            total:0,
            total2:0,
            // 年
            timeThreeList: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            typeData: [],
            product_type: [],

            options: [{
                value: '选项1',
                label: '福建韭黄'
            }, {
                value: '选项2',
                label: '海南韭黄'
            }, {
                value: '选项3',
                label: '广州韭黄'
            }, {
                value: '选项4',
                label: '广西韭黄' 
            },
            ],
            value: ''
        }
    },
    mounted(){
        this.getEcharts1();
        this.getEcharts2();
        // this.getEcharts3();
        this.requrstList();
        this.policyList();
        this.annualEquipmentData();
    },
    methods: {

         //新闻列表数据
        requrstList(where) {
            this.$get('/enterpriseNews/getNewsList', { page: this.page, size: this.size, newsTitle: where || '' }).then((res) => {
                //console.log(res)
                if (res.data.state == 'success') {
                this.tableData = [];
                this.total = res.data.count;
                let num = res.data.datas.length - 1;
                this.tableData = res.data.datas[num];
                }
                setTimeout(() => {
                this.loading = false; 
                }, 500);
            });
        },
        //种植政策列表
        policyList(where){
            this.$get('/plantpolicy/getPolicyList', {page: this.page, size: this.size, policyTitle: where || '' }).then((res) =>{
                if(res.data.state == 'success'){
                    this.tableData2 = [];
                    this.total2 = res.data.count;
                    res.data.datas.forEach((item) => {
                        if (item.del !== 1) {
                        this.tableData2.push(item);
                        }
                    });
                }
            })
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },

        getEcharts1(){
        var chartDom = document.getElementById('main');
        var myChart = echarts.init(chartDom);
        var option;
        option = {
        title: {
            text: '品牌销量占比',
            // subtext: 'Fake Data',
            left: '120px',
            top:'20px',
        },
        tooltip: {
            trigger: 'item'
        },
        // legend: {
        //     orient: 'vertical',
        //     left: 'right'
        // },
        series: [
            {
            name: '品牌销量占比',
            type: 'pie',
            radius: '50%',
            center: ['38%','45%'],
            data: [
                { value: 787, name: '山西品牌 25%', itemStyle:{color: '#e67300'} },
                // { value: 735, name: 'Direct' },
                // { value: 2360, name: '山东品牌 75%' },
                // { value: 484, name: 'Union Ads' },
                { value: 2360, name: '山东品牌 75%', itemStyle:{color: '#29b8e1'} }
            ],
            emphasis: {
                
                itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
            }
            }
        ],
        };
        option && myChart.setOption(option);
        },
        getEcharts2(){
            var chartDom = document.getElementById('main2');
            var myChart = echarts.init(chartDom);
            var option;

            option = {
                title: {
                text: '品种销量占比',
                right: '200px',
                top:'20px',
            },
            tooltip: {
                trigger: 'item'
            },
            legend: { //图例
                // top: '5%',
                orient: 'vertical',
                // icon: "rectangle",
                itemWidth: 18,
                left: '70%',
                y: 'center',
                textStyle: {
                    fontSize: 13
                },
                data: ['福建韭黄','海南韭黄','广州韭黄','广西韭黄']
            },
            series: [
                {
                name: '品种销量占比',
                type: 'pie',
                radius: ['32%', '70%'],
                center: ['28%','60%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                    // show: true,
                    fontSize: '40',
                    fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: [
                    // { value: 1048, name: 'Search Engine' },
                    { value: 300, name: '福建韭黄', itemStyle:{color: '#ffff4d'}  },
                    { value: 580, name: '海南韭黄', itemStyle:{color: '#e60000'}  },
                    { value: 1048, name: '广西韭黄', itemStyle:{color: '#468600'}  },
                    { value: 735, name: '广州韭黄', itemStyle:{color: '#6767ec'}  }
                ] 
                }
            ]
            };

            option && myChart.setOption(option);
        },

        //当年
        annualEquipmentData() {
            this.$get("bigdata/priceTrend", {}).then((res) => {
                if (res.data.state == "success") {
                    let datas = res.data.data;
                    let typeData = [];
                    for(let i = 0;i < datas.length;i++){
                        let product_type = datas[i][0].product_type;
                        let price = datas[i][0].price;
                        this.product_type.push(product_type);
                        this.typeData.push(price);
                        //console.log(this.typeData);
                    }
                    this.getEcharts3();
                }
            });
        },
        //年度趋势
        getEcharts3() {
            var chartDom = document.getElementById('main3');
            var myChart = echarts.init(chartDom);
            var option;

            option = {
            title: {
                text: '全国销量分布产品价格趋势',
            },
            tooltip: {
                trigger: 'item'
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12月']
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                data: [4.2, 3.9, 5.8, 4.2, 3.6, 5, 5.5, 6.3, 4.08, 2.6, 3.5, 6.6],
                // data: this.typeData,
                
                type: 'line',
                symbol: 'none', //取消折线点
                lineStyle: {      // 阴影部分
                    color: '#6dfef9', //折线颜色
                    shadowOffsetX: 1, // 折线的X偏移    
                    shadowOffsetY: 10,// 折线的Y偏移  
                    shadowBlur: 10,  // 折线模糊
                    shadowColor: "rgba(145, 132, 132, 1)", //阴影颜色
                },
                areaStyle: {  //设置图形颜色
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{  //设置渐变
                            offset: 0, color: '#8ec5f8' // 0% 处的颜色
                        }, {
                            offset: 1, color: '#f6fefe' // 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    }
                }
                }
            ]
            };

            option && myChart.setOption(option);
        }
    }
}
</script>

<style scoped lang="less">
    .title{
        border-bottom: 1px #999 solid ;
        padding-bottom: 10px;
        font-size: 12px;
        font-weight: bold;
    }
    .box-card{
        width: 98%;
        // height: 850px;
        margin: 0 auto;
        border-radius: 10px;
        overflow: hidden;
    }
    .card-first{
        width: 900px;
        height: 250px;
        margin-top: 20px;
        float: left;
    }
    .card-second{
        float: left;
        margin-left: 30px;
        margin-top: 20px;
        width: 600px;
        height: 350px;
    }
    .card-third{
        float: left;
        margin-top: -80px;
        width: 900px;
        height: 450px;
        position: relative;
    }
    .card-four{
        float: left;
        margin-left: 30px;
        margin-top: 20px;
        width: 600px;
        height: 350px;
    }
    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }
    .clearfix:after {
        clear: both
    }
    .clearfix span{
        font-size: 18px;
    }
    // .header-contant{
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: space-around;
    //     align-items: flex-start;
    // }
    .contant{
        text-align: center;
        // background-color: pink;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: 0;
    }
    .contant .contant-title{
        font-size: 15px;
    }
    .header-contant p{
        line-height: 30px;
        font-size: 13px;
    }
    .check{
        color: skyblue;
        cursor: pointer;   
    }
    .select{
        position: absolute;
        top: 10px;
        right: 70px;
        width: 150px; 
    }
</style>